<template>
  <div>
    <el-card>
      <div class="flex flex-between flex-v-center">
        <div class="flex flex-v-center">
          <el-image v-if="!search && logo" class="l-img br" style="height: 100px;" fit="contain" :src="logo">
          </el-image>
          <div class="margin-x">

            <div>
              <h3>{{ search ? $t('搜索结果') + ' (' + search + ')' : $t1(type, 'title') }}</h3>
            </div>

            <div class="mt margin-t">{{ $t('已掌握') + ($store.state.lang == 'en' ? ' ' : '') }}{{ search ? total0 :
              type.record_count }}/{{ search ? total :
                type.words_count }}
            </div>
            <!-- <div class="mt margin-t">{{ type.description }}</div> -->
          </div>
        </div>

        <div>

          <div class="right">
            <el-link @click="goBack" type="primary">
              <div class="flex flex-v-center"><i class="el-icon-refresh-left" style="font-size:18px;"></i>
                <h3>{{
                  search ? $t('返回') : $t('返回单词本')
                  }}</h3>
              </div>
            </el-link>
          </div>

          <div class="flex flex-v-center margin-t">

            <el-button v-if="tag == 'mine'" @click="add" plain round icon="el-icon-plus" style="margin-right: 40px;">{{
              $t('自我添加单词') }}</el-button>

            <el-radio-group v-model="mode" @change="changeMode">
              <el-radio-button label="普通模式"><i class="el-icon-notebook-2 mode-icon"></i>{{ $t('普通模式')
              }}</el-radio-button>
              <el-radio-button label="背诵模式"><i class="el-icon-notebook-1 mode-icon"></i>{{ $t('背诵模式')
              }}</el-radio-button>
              <el-radio-button label="听写模式"><i class="el-icon-headset mode-icon"></i>{{ $t('听写模式') }}</el-radio-button>
            </el-radio-group>
          </div>
        </div>

      </div>
    </el-card>

    <el-card class="margin-t" style="position:relative;">

      <div class="flex flex-between">
        <div class="flex flex-v-center flex-wrap" style="gap: 10px;">
          <el-select v-model="status" @change="query(1)"
            :style="{ width: $store.state.lang == 'en' ? '130px' : '100px' }">
            <el-option :label="$t('全部')" value="全部"></el-option>
            <el-option :label="$t('未掌握')" value="未掌握"></el-option>
            <el-option :label="$t('已掌握')" value="已掌握"></el-option>
          </el-select>

          <!-- <el-radio-group v-model="status" @change="query(1)">
              <el-radio-button label="全部"></el-radio-button>
              <el-radio-button label="未掌握"></el-radio-button>
              <el-radio-button label="已掌握"></el-radio-button>
            </el-radio-group> -->

          <el-select v-if="tag == 'mine'" v-model="sort" style="width:140px" :placeholder="$t('请选择排序')"
            @change="query(1)">
            <el-option :label="$t('按添加时间')" value="按添加时间"></el-option>
            <el-option v-if="tag == 'mine'" :label="$t('按字母顺序')" value="按字母顺序"></el-option>
            <!-- <el-option label="按星级" v-if="tag == 'mine'" value="按星级"></el-option> -->
          </el-select>


          <el-select v-model="sort_value" :style="{ width: $store.state.lang == 'en' ? '140px' : '100px' }"
            :placeholder="$t('请选择排序')" @change="sortChange">
            <el-option :label="$t('正序')" value="ASC">{{ $t('正序') }}<i class="el-icon-top"></i></el-option>
            <el-option :label="$t('倒序')" value="DESC">{{ $t('倒序') }}<i class="el-icon-bottom"></i></el-option>
            <el-option :label="$t('乱序')" value="RANDOM">{{ $t('乱序') }}<i class="el-icon-right"></i></el-option>
          </el-select>


          <el-cascader :show-all-levels="false" :style="{ width: $store.state.lang == 'en' ? '225px' : '160px' }"
            v-if="tag == 'mine'" :placeholder="$t('选择单词本')" @change="query(1)" v-model="tag1"
            :options="options1"></el-cascader>

          <!-- <el-select v-if="tag == 'mine'" v-model="tag1" class="margin-x" @change="query(1)" style="width:160px;">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="(item, index) in $store.state.books.slice(1)" :key="index" :label="item.title"
                :value="item.tag"></el-option>
            </el-select> -->

          <el-button @click="removeRecord()" plain icon="el-icon-s-flag">{{ $t('清除已掌握') }}</el-button>
          <div>
            <el-button @click="addRecords()"><i class="el-icon-s-flag pointer"
                style="font-size:14px;color:#E6A23C;"></i>{{ $t('批量已掌握') }}</el-button>
          </div>
          <div>

            <el-dropdown type="primary" @command="pdf">
              <el-button plain icon="el-icon-document">{{ $t('导出') }}</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="txt">{{ $t('导出TXT') }}</el-dropdown-item>
                <el-dropdown-item command="pdf">{{ $t('导出PDF') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <!-- <el-popover placement="top-start" title="" width="400" trigger="hover">
              <div>
                <el-button slot="reference" @click="pdf" plain icon="el-icon-document">{{ $t('导出TXT') }}</el-button>
                <el-button slot="reference" @click="pdf" plain icon="el-icon-document">{{ $t('导出PDF') }}</el-button>
              </div>
              <el-button slot="reference" plain icon="el-icon-document">{{ $t('导出') }}</el-button>
            </el-popover> -->

          </div>
          <el-switch v-if="mode == '听写模式'" class="margin-l" v-model="autoPlay" :active-text="$t('自动播放')"
            active-color="#E6A23C"></el-switch>

          <el-switch v-if="mode == '听写模式'" class="margin-l" v-model="autoRecord" :active-text="$t('自动掌握')"
            active-color="#E6A23C"></el-switch>
        </div>
        <div class="flex flex-between flex-v-center" :style="{ width: $store.state.lang == 'en' ? '300px' : '280px' }"
          style="flex-shrink: 0;">
          <div class="flex flex-v-center">
            <el-rate v-model="stars" @change="query(1)"></el-rate>
            <div v-if="stars > 0" style="height: 15px;width: 1px;background-color: #cccccc;margin-right: 5px;"></div>
            <!-- <el-divider v-if="stars > 0" direction="vertical"></el-divider> -->
            <el-link v-if="stars > 0" @click="resetStars" type="primary">{{ $t('重置') }}</el-link>
          </div>
          <div class="mt margin-r">{{ $t('共') }}{{ $num(total) }}{{ $t('词') }}</div>
        </div>
      </div>
      <div class="line margin-t"></div>
      <el-table @selection-change="handleSelectionChange" v-if="showTable" v-loading="loading" class="margin-t table"
        :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column :label="$t('单词')" width="260px">
          <template slot-scope="scope">
            <div class="flex flex-v-center">
              <!-- <span>{{ scope.$index + 1 }}.</span> -->
              <div @click="play(scope.row.word, phoneticType == '英式' ? 1 : 2)" v-if="mode == '听写模式'">
                <el-input style="min-width: 220px;" @focus="scope.row.state = true" @blur="scope.row.state = false"
                  :ref="'input' + scope.$index" :placeholder="$t('听写') + '...'" v-model="scope.row.word_input"
                  :maxlength="scope.row.word.length" @input="inputWord(scope.row)" @change="inputWord(scope.row)"
                  :id="scope.$index" @keydown.tab.native="nextInput" size="small">


                  <el-popover slot="suffix"
                    v-if="!scope.row.word_input || scope.row.word_input.length < scope.row.word.length" placement="top"
                    title="" width="100" trigger="click">
                    <h3 class="center">{{ scope.row.word }}</h3>
                    <i slot="reference" class="el-icon-view el-input__icon pointer">
                    </i>
                  </el-popover>



                  <i v-if="scope.row.word == scope.row.word_input" class="el-icon-check el-input__icon"
                    style="color:green;font-size: 30px;font-weight: bold;" slot="suffix">
                  </i>

                  <i v-if="scope.row.word_input && (scope.row.word_input.length == scope.row.word.length || !scope.row.state) && scope.row.word_input != scope.row.word"
                    class="el-icon-close el-input__icon" @click="scope.row.word_input = ''"
                    style="color:red;font-size: 30px;font-weight: bold;" slot="suffix">
                  </i>
                </el-input>
              </div>

              <h3 v-else class="pointer break flex1 flex flex-v-center">{{ scope.row.word }} <word-popover
                  :info="scope.row" style="margin-top:10px;"></word-popover> <el-tooltip
                  v-if="tag == 'mine' && mode == '普通模式'" class="margin-l" effect="dark" :content="$t('删除')"
                  placement="top">
                  <el-link @click="removeMine(scope.row)">
                    <i class="el-icon-delete"></i>
                  </el-link>
                </el-tooltip>
              </h3>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('发音')" width="260px">
          <template #header v-if="mode == '听写模式'">
            <div class="margin-ls flex flex-v-center">
              {{ $t('发音') }}(
              <el-switch :active-text="$t('美式')" :inactive-text="$t('英式')" active-value="美式" inactive-value="英式"
                v-model="phoneticType"></el-switch>)
            </div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.phonetic && mode != '听写模式'">
              <div class="st1" v-if="scope.row.phonetic[0]">
                UK /{{ scope.row.phonetic[0].toString() }}/
                <el-link class="margin-ls" type="primary" @click="play(scope.row.word, 1)"> <i
                    class="el-icon-headset"></i></el-link>
              </div>
              <div class="st1" v-if="(scope.row.word.split(' ').length == 1 && scope.row.phonetic[1])">US /{{
                scope.row.phonetic[1].toString()
                }}/<el-link class="margin-ls" type="primary" @click="play(scope.row.word, 2)">
                  <i class="el-icon-headset"></i></el-link>
              </div>
            </div>

            <div v-if="mode == '听写模式'">
              <el-link class="margin-ls" type="primary" @click="play(scope.row.word, phoneticType == '英式' ? 1 : 2)"> <i
                  class="el-icon-headset"></i></el-link>

              <el-popover class="margin-l" placement="top-start" title="" width="200" trigger="click">
                <div class="st1" v-if="scope.row.phonetic[0]">
                  UK /{{ scope.row.phonetic[0].toString() }}/
                </div>
                <div class="st1" v-if="(scope.row.word.split(' ').length == 1 && scope.row.phonetic[1])">US /{{
                  scope.row.phonetic[1].toString()
                  }}/
                </div>
                <el-link type="primary" slot="reference">{{ $t('音标已隐藏') }}<i class="el-icon-thumb"
                    style="margin-left:5px"></i></el-link>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('释义')">
          <template slot-scope="scope">


            <div v-if="mode == '背诵模式' || mode == '听写模式'">
              <el-popover placement="top-start" title="" width="200" trigger="click">
                <div class="flex flex-wrap" style="column-gap: 4px;"
                  v-for="(row, index) in $t1(scope.row, 'explanation').replace(/\[^\]+\]/g, (val) => { return val + '\n' }).split('\n')"
                  :key="index">
                  <span v-for="(word, index1) in row.split(' ')" :key="index1">{{ word }}</span>
                </div>
                <el-link type="primary" slot="reference">{{ $t('释义已隐藏') }}<i class="el-icon-thumb"
                    style="margin-left:5px"></i></el-link>
              </el-popover>

            </div>
            <div v-else>
              <div v-for="(row, index) in (scope.row.mine_explanation || $t1(scope.row, 'explanation')).split('\n')"
                :key="index">
                {{
                  row }}</div>

              <el-link @click="editExplanation(scope.row, scope.$index)" v-if="tag == 'mine'" type="primary"><i
                  class="el-icon-edit"></i>{{ $t('修改释义') }}</el-link>
              <div v-if="scope.row.sentence">
                <!-- <el-divider>例句:</el-divider> -->
                <div class="line margin-t"></div>
                <div class="mt" v-for="(row, index) in scope.row.sentence.split('\n')" :key="index">
                  <template v-if="$store.state.lang != 'en' || index % 2 == 0">
                    <div v-if="(row.indexOf('^') != -1)" :class="{ 'break': index % 2 == 0 }" class="st1">
                      <span :class="index % 2 == 1 ? 'bold1' : ''" v-for="(part, index) in row.split('^')"
                        :key="index">{{
                          part
                        }}</span>
                    </div>

                    <div v-else-if="(row.toLowerCase().indexOf(scope.row.word.toLowerCase()) != -1)" class="break st1">
                      <span>{{ row.substring(0, row.toLowerCase().indexOf(scope.row.word.toLowerCase())) }}</span>
                      <span class="bold1">{{
                        row.substr(row.toLowerCase().indexOf(scope.row.word.toLowerCase()),
                          scope.row.word.length)
                      }}</span>

                      <span>{{
                        row.substr(row.toLowerCase().indexOf(scope.row.word.toLowerCase()) + scope.row.word.length)
                      }}</span>
                    </div>

                    <div v-else class="break st1">
                      {{ row }}
                    </div>
                  </template>
                </div>


              </div>
            </div>
          </template>
        </el-table-column>


        <el-table-column :label="$t('加入我的单词表')" width="200px" v-if="tag != 'mine'">
          <template slot-scope="scope">
            <el-cascader size="small" clearable :placeholder="$t('选择单词本')" @change="addMine(scope.row)"
              v-model="scope.row.mine" :options="options">
              <template slot-scope="{ node, data }">
                <el-rate v-if="node.isLeaf" :value="data.value" :max="data.value" disabled> </el-rate>
                <span v-else>{{ data.label }}</span>
              </template>

            </el-cascader>
          </template>
        </el-table-column>

        <!-- <el-table-column label="PTE/CCL" width="90px" v-if="tag == 'mine'">
          <template slot-scope="scope">

            <span v-if="scope.row.from_tag">
              {{ $store.getters.getBook(scope.row.from_tag).type == 1 ? 'CCL' : 'PTE' }}
            </span>

            <span v-else>
              N/A
            </span>

          </template>
        </el-table-column> -->



        <el-table-column :label="$t('词本/题型')" width="120px" v-if="tag == 'mine'">
          <template slot-scope="scope">
            <el-cascader size="mini" v-if="!scope.row.from_type && !scope.row.from_tag" slot="content"
              :show-all-levels="false" placeholder="N/A" @change="updateFrom(scope.row)" v-model="scope.row.from"
              :options="options2"></el-cascader>

            <span v-else>
              {{ scope.row.from_type ? scope.row.from_type :
                ($store.getters.getBookName(scope.row.from_tag).replace("/", '') || 'N/A') }}
            </span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('星级')" width="200px" v-if="tag == 'mine'">
          <template slot-scope="scope">
            <div class="flex flex-v-center">
              <el-rate :disabled="$store.getters.getRole() == 0" @change="updateStars(scope.row)"
                v-model="scope.row.stars"></el-rate>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('已掌握')" width="84px">

          <template slot="header">
            <el-dropdown @command="handleCommand">
              <el-link type="primary">{{ $t('已掌握') }}<i class="el-icon-arrow-down el-icon--right"></i></el-link>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1" class="flex flex-v-center"><i class="el-icon-s-flag pointer"
                    style="font-size:30px;color:#E6A23C;"></i>{{ $t('本页全部掌握') }}</el-dropdown-item>
                <el-dropdown-item command="0" class="flex flex-v-center"><i class="el-icon-s-flag pointer"
                    style="font-size:30px;color:#909399;"></i>{{ $t('本页全部清除') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>

          <template slot-scope="scope">
            <el-tooltip effect="dark" :content="scope.row.times ? $t('已掌握') : $t('未掌握')" placement="top">
              <el-link @click="addRecord(scope.row)"> <i class="el-icon-s-flag pointer" style="font-size:30px"
                  :style="{ color: scope.row.times ? '#E6A23C' : '#909399' }"></i>
              </el-link>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <div class="center margin-t">
        <el-pagination background :current-page.sync="currPage" @current-change="pageChange"
          layout="prev, pager, next, jumper" :pageSize="20" :total="total">
        </el-pagination>
      </div>
    </el-card>
    <audio controls ref="audio" style="display:none;">
      <source :src="src" />
    </audio>

    <el-dialog center :visible.sync="dialogVisible" top="15%" width="600px">
      <div slot="title">
        <h2>{{ formData.word }}</h2>
      </div>

      <el-form ref="form" size="small" :model="formData" label-width="80px">

        <el-form-item :label="$t('释义')">
          <el-input type="textarea" :rows="3" v-model="formData.explanation" :placeholder="$t('请输入反馈内容')"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button round style="width:200px" type="primary" @click="explanationSubmit">{{ $t('提交释义') }}</el-button>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import WordPopover from '../../components/WordPopover.vue';

export default {
  components: {
    WordPopover
  },
  data() {
    return {
      phoneticType: '英式',
      showTable: true,
      autoPlay: false,
      autoRecord: false,
      loading: false,
      mode: "普通模式",
      tableData: [],
      total: 0,
      tag: "",
      tag1: ["", ""],
      book_id: 0,
      page: 1,
      src: "",
      // type: {},
      wordIndex: 0,
      status: '全部',
      sort: '按字母顺序',
      sort_value: "ASC",
      books: [],
      stars: 0,
      search: "",
      total0: 0,
      currPage: 1,
      dialogVisible: false,
      formData: { id: 0, explanation: '', word: '', index: -1 },
      seed: 0,
      selectedRows: [],
    };
  },

  computed: {
    type() {
      return this.$store.getters.getBook(this.tag, this.book_id) || {};
    },

    logo() {
      if (this.type && this.type.logo) {
        return this.$host + this.$url(this.type, 'logo')
      }
      const books = this.$store.getters.getBooks(-1)
      if (books.length > 0) {
        return this.$host + this.$url(books[0], 'logo')
      }
      return ''
    },

    options1() {
      let children1 = [];
      this.$store.state.books.slice(1).forEach((item) => {
        children1.push({
          value: item.tag,
          label: item.title,
        })
      });

      let children2 = [];
      this.$store.state.types.forEach((item) => {
        children2.push({
          value: item.tag,
          label: item.tag,
        })
      });


      return [
        {
          value: "",
          label: this.$t("全部"),
        },
        {
          value: "from_tag",
          label: this.$t("单词本"),
          children: children1
        },
        {
          value: "from_type",
          label: this.$t("题库"),
          children: children2
        },
        {
          value: "self_add",
          label: this.$t("自我添加"),
        }
      ]
    },

    options2() {
      let children1 = [];
      let type = this.$store.state.mode == "CCL" ? 1 : 0
      this.$store.state.books.slice(1).forEach((item) => {
        if (item.type == type) {
          children1.push({
            value: item.tag,
            label: this.$t1(item, 'title'),
          })
        }
      });

      let children2 = [];
      this.$store.state.types.forEach((item) => {
        if (item.type == type) {
          children2.push({
            value: item.tag,
            label: item.tag,
          })
        }
      });


      return [
        {
          value: "from_tag",
          label: this.$t("单词本"),
          children: children1
        },
        {
          value: "from_type",
          label: this.$t("题库"),
          children: children2
        }
      ]
    },


    options() {
      let stars = [{ value: 1, label: "1星" }, { value: 2, label: "2星" }, { value: 3, label: "3星" }, { value: 4, label: "4星" }, { value: 5, label: "5星" },]
      let options = [{
        value: 0,
        label: this.$t("我的单词本"),
        children: stars,
      }];

      if (this.$store.getters.getRole() > 0) {
        this.$store.state.mineBooks.forEach((item) => {
          options.push({
            value: item.id,
            label: item.title,
            children: stars
          })
        })
      }
      return options;
    },
  },

  watch: {
    $route() {
      this.tag = this.$route.query.tag;
      this.book_id = this.$route.query.book_id || 0;
      this.search = this.$route.query.search || "";
      if (this.search) {
        this.page = 1;
      } else {
        // this.page = parseInt(this.$store.state.vocabularyPage) || 1;
        this.page = this.$route.query.page || 1;
      }
      this.query();
    },
    phoneticType(val) {
      localStorage.setItem('vocabulary_phonetic_type', val);
    },
    autoPlay(val) {
      localStorage.setItem('vocabulary_auto_play', val);
    },
    autoRecord(val) {
      localStorage.setItem('vocabulary_auto_record', val);
    }
  },

  created() {
    this.tag = this.$route.query.tag;
    this.book_id = this.$route.query.book_id || 0;

    this.search = this.$route.query.search || "";
    this.page = this.$route.query.page || 1;

    this.autoPlay = (localStorage.getItem('vocabulary_auto_play') == 'true') || false;
    this.autoRecord = (localStorage.getItem('vocabulary_auto_record') == 'true') || false;
    this.phoneticType = localStorage.getItem('vocabulary_phonetic_type') || '英式'
    if (this.tag == "mine") {
      this.sort = "按添加时间";
    }


    if (!this.$route.query.search && !this.$route.query.page) {
      let filterStr = localStorage.getItem(this.tag + "_" + this.book_id);
      if (filterStr) {
        let filter = JSON.parse(filterStr);
        this.page = filter.page;
        this.sort = filter.sort;
        this.sort_value = filter.sort_value;
        this.stars = filter.stars;
        this.status = filter.status;
        this.seed = filter.seed;
      }

      let mode = localStorage.getItem(this.tag + "_mode_" + this.book_id);
      if (mode) this.mode = mode;
    }




    this.query();
    this.$bus.$on("update_word", (word) => {
      this.tableData[this.wordIndex].times = word.times;
    })

    this.$bus.$emit("aside", false);
    this.$store.dispatch("fetch_mine_books");
    this.$store.dispatch("fetch_books");
  },

  methods: {

    handleSelectionChange(val) {
      this.selectedRows = val;
    },

    addRecords() {
      if (this.selectedRows.length == 0) {
        this.$message.info(this.$t('请先选择单词'))
      } else {
        let words = []
        this.selectedRows.forEach(row => {
          if (!row.times) {
            words.push(row.word)
          }
        })
        this.$http.post("/api/word/record", { words: words, tag: this.tag, book_id: this.book_id }).then((count) => {
          this.$store.commit("updateBook", [this.tag, this.book_id, count])
          this.selectedRows.forEach((item) => {
            item.times = 1;
          })
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        });
      }
    },

    handleCommand(flag) {
      let words = []

      if (flag == 1) {
        this.tableData.forEach(row => {
          if (!row.times) {
            words.push(row.word)
          }
        })
        this.$http.post("/api/word/record", { words: words, tag: this.tag, book_id: this.book_id }).then((count) => {
          this.$store.commit("updateBook", [this.tag, this.book_id, count])
          this.tableData.forEach((item) => {
            item.times = 1;
          })
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        });
      } else {
        this.tableData.forEach(row => {
          if (row.times) {
            words.push(row.word)
          }
        })
        this.removeRecord(words)
      }
    },

    randomRange(min, max) { // min最小值，max最大值
      return Math.floor(Math.random() * (max - min)) + min;
    },

    sortChange(val) {
      if (val == 'RANDOM') {
        this.seed = this.randomRange(1, 1000);
      } else {
        this.seed = 0;
      }
      this.query(1);
    },
    nextInput(e) {
      console.log(e)
      e.preventDefault();
      let index = parseInt(e.target.id) + 1;
      this.$refs["input" + index].focus();
      if (this.tableData[index]) {
        this.play(this.tableData[index].word, this.phoneticType == '英式' ? 1 : 2)
      }
    },

    explanationSubmit() {
      this.$http.post("/api/word/explanation", { id: this.formData.id, explanation: this.formData.explanation }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success"
        })
        this.query();
        this.dialogVisible = false;
      });
    },

    editExplanation(row, index) {

      this.formData.id = row.mine_id;
      this.formData.index = index;
      this.formData.word = row.word;
      this.formData.explanation = row.mine_explanation || row.explanation;
      this.dialogVisible = true;
    },

    pdf(type) {
      if (this.$store.getters.getRole() == 0) {
        this.$vip_alert();
      } else {
        let filter = { tag: this.tag, status: this.status, sort: this.sort, sort_value: this.sort_value, page: this.page, book_id: this.book_id, stars: this.stars, search: this.search, seed: this.seed, lang: this.$store.state.lang, type: type };
        if (this.tag1[0]) {
          if (this.tag1[0] == 'self_add') {
            filter[this.tag1[0]] = 1;
          } else {
            filter[this.tag1[0]] = this.tag1[1];
          }
        }
        this.$http.post("/api/word/code", filter).then((code) => {
          window.open(this.$host + "/api/word/pdf/" + code);
        });
      }


    },

    updateFrom(row) {
      console.log(row.from)
      let data = { word: row.word, book_id: this.book_id };
      data[row.from[0]] = row.from[1];
      if (row.from[0] == "from_tag") {
        data["from_type"] = "";
      } else {
        data["from_tag"] = "";
      }
      this.$http.post("/api/word/mine", data).then(() => {
        this.$message({
          message: this.$t('提交成功'),
          type: 'success'
        });
      });
    },

    add() {
      if (this.$store.getters.getRole() > 0) {
        this.$prompt('', this.$t('自我添加单词'), {
          inputPlaceholder: this.$t('请输入单词'),
          center: true,
          roundButton: true,
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
        }).then(({ value }) => {
          if (value) {
            let word = value.trim();
            this.$http.post("/api/word/mine", { word: word, book_id: this.book_id, stars: 1, from_tag: "" }).then(() => {
              this.$message({
                message: this.$t('提交成功'),
                type: 'success'
              });
              this.query(1);
            });
          }
        }).catch(() => {

        });
      } else {
        this.$vip_alert("添加单词本");
      }
    },


    changeMode() {
      if (this.$store.getters.getRole() == 0 && this.mode != "普通模式") {
        this.$vip_alert(this.mode);
        this.$nextTick(() => {
          this.mode = "普通模式"
        })
      }
      localStorage.setItem(this.tag + "_mode_" + this.book_id, this.mode);
      this.showTable = false;
      this.$nextTick(() => {
        this.showTable = true;
      })
    },
    resetStars() {
      this.stars = 0;
      this.query(1);
    },

    inputWord(info) {
      console.log(info)
      if (info.word.indexOf(' ') == -1) {
        info.word_input = info.word_input.trim()
      } else {
        info.word_input = info.word_input.replace(/\s+/g, ' ').replace(/^\s/, '')
      }
      if (info.word == info.word_input) {

        if (!info.times && this.autoRecord) {
          this.addRecord(info)
          info.times = 1;
        }
        this.$refs["input" + (info.index + 1)].focus();
        let nextInfo = this.tableData[info.index + 1];
        if (nextInfo && this.autoPlay) {
          this.play(nextInfo.word, this.phoneticType == '英式' ? 1 : 2)
        }
      }
    },

    getPhonetic(info) {
      this.$http.post("/api/word/phonetic", { word: info.word }).then((phonetic) => {
        this.tableData[info.index]["phonetic"] = phonetic;
        this.$message({
          type: 'success',
          message: this.$t('更新成功,感谢您的帮助') + '!'
        });
      })
    },

    removeRecord(words) {
      this.$confirm('', this.$t('确认清除已掌握') + '?', {
        center: true,
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        confirmButtonClass: "confirm-btn",
        type: 'primary'
      }).then(() => {
        let param = words ? { words: words } : { tag: this.tag, book_id: this.book_id }
        this.$http.post("/api/word/record/remove", param).then((count) => {
          this.tableData.forEach((item) => {
            item.times = null;
          })
          if (count) {
            this.$store.commit("updateBook", [this.tag, this.book_id, 0 - count])
          }

          this.$message({
            type: 'success',
            message: this.$t('删除成功') + '!'
          });
        })
      }).catch(() => {

      });
    },

    addRecord(info) {
      if (!info.times) {
        this.$http.post("/api/word/record", { word: info.word }).then(() => {
          info.times = 1;
          this.$store.commit("updateBook", [this.tag, this.book_id, 1])
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        });
      } else {
        this.$http.post("/api/word/record/remove", { word: info.word }).then(() => {
          info.times = null;
          this.$store.commit("updateBook", [this.tag, this.book_id, -1])
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        });
      }
    },

    changeWord(info) {
      console.log(info)
    },

    play(word, type) {
      this.src = this.$host + "/api/word/audio/" + word + "/" + type
      this.$refs.audio.src = this.src;
      this.$refs.audio.play();
    },

    handleClick(e) {
      this.status = e.index;
      this.query(1);
    },

    updateStars(info) {
      this.$http.post("/api/word/mine", { word: info.word, stars: info.stars }).then(() => {
        this.$message({
          message: this.$t('提交成功'),
          type: 'success'
        });
      });
    },


    removeMine(info) {

      this.$confirm('', this.$t('确认从单词本中删除该单词') + '?', {
        center: true,
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        confirmButtonClass: "confirm-btn",
        type: 'primary'
      }).then(() => {
        this.$http.post("/api/word/mine/remove", { id: info.mine_id }).then(() => {
          this.query();
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        });
      }).catch(() => {

      });



    },

    addMine(info) {
      if (info.mine.length > 0) {
        if (this.$store.getters.getRole() == 0 && info.mine[1] > 1) {
          this.$vip_alert("添加单词本");
          setTimeout(() => {
            info.mine = info.mine_origin
          }, 500);

        } else {
          this.$http.post("/api/word/mine", { word: info.word, book_id: info.mine[0], stars: info.mine[1] || 1, from_tag: this.tag }).then(() => {
            info.mine_origin = info.mine;
            this.$message({
              message: this.$t('提交成功'),
              type: 'success'
            });
          });
        }

      } else {
        this.$http.post("/api/word/mine/remove", { id: info.mine_id }).then(() => {
          info.mine_origin = info.mine;
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        });
      }
    },

    goBack() {
      if (this.search) {
        this.$bus.$emit("search_back");
        this.$router.back();
      } else {
        this.$router.replace("/main/wordbook")
      }
    },


    pageChange(page) {
      if (this.book_id) {
        this.$router.replace("/main/vocabulary?tag=" + this.tag + "&book_id=" + this.book_id + "&page=" + page)
      } else {
        this.$router.replace("/main/vocabulary?tag=" + this.tag + "&page=" + page)
      }

    },

    query(page) {
      this.loading = true;
      if (page) this.page = page;
      if (!this.search) {
        this.$store.commit("set_vocabulary_page", this.page);
      }

      this.total0 = 0;
      this.tableData = [];
      let filter = { tag: this.tag, status: this.status, sort: this.sort, sort_value: this.sort_value, page: this.page, book_id: this.book_id, stars: this.stars, search: this.search, seed: this.seed };

      if (this.tag1[0]) {
        if (this.tag1[0] == 'self_add') {
          filter[this.tag1[0]] = 1;
        } else {
          filter[this.tag1[0]] = this.tag1[1];
        }
      }

      if (!this.search) {
        localStorage.setItem(this.tag + "_" + this.book_id, JSON.stringify(filter))
      }

      this.$http.post("/api/word/list", filter).then((res) => {
        let data = res.data;
        data.forEach((item, index) => {
          item.state = false;
          item.mine_origin = item.mine;
          item.index = index;

          // item.explanation = item.explanation.replace(/; \w+/, (word) => {
          //   return "; ^" + word.split(";")[1];
          // });

          if (item.times) {
            this.total0++;
          }

          if (item.phonetic) {
            item.phonetic.forEach((phonetic, index) => {
              let phonetics = [];
              if (phonetic) {
                if (typeof phonetic == 'object') {
                  phonetic.forEach((phonetic1) => {
                    phonetics.push(phonetic1.split(";")[0]);
                  })
                } else {
                  phonetics.push(phonetic.split(";")[0]);
                }
              }
              item.phonetic[index] = phonetics;
            })
          }
        })
        this.tableData = data;
        this.total = res.total;
        this.loading = false;
        setTimeout(() => {
          this.currPage = res.current_page;
        }, 500);
      })
    },
    // query_books() {
    //   this.$http.post("/api/book/list").then((books) => {
    //     let options = [{
    //       value: 0,
    //       label: "我的单词本",
    //       children: this.stars
    //     }];
    //     books.forEach((item) => {
    //       options.push({
    //         value: item.id,
    //         label: item.title,
    //         children: this.stars
    //       })
    //     })
    //     this.options = options;
    //   })
    // }
  },
};
</script>

<style scoped>
.header {
  padding: 0 28px;
  background-image: linear-gradient(0deg, #1ad0b3, #23ccd1), linear-gradient(#fff, #fff);
  border-radius: 10px;
  color: #fff;
}


.l-img {
  height: 160px;
  width: 120px;
}

.mode-icon {
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
}

.bold1 {
  font-weight: bold;
  color: red;
  font-size: 16px;
  text-decoration: underline;
}

.table>>>.el-collapse-item__header,
.table>>>.el-collapse-item__wrap {
  background-color: transparent;
}

.table>>>.el-collapse {
  border-top: none;
  border-bottom: none;
}

.table>>>.el-collapse-item__arrow {
  font-size: 0;
}

.table>>>.el-table-column--selection .cell {
  text-align: center !important;
}

.table>>>.el-collapse-item__header {
  height: 30px;
  line-height: 30px;
}
</style>
